.card {
    text-align: center;
    font-size: 20px;
    background-color: #f7f7f7;
}
#rcard {
    font-size: 24px;
    background-color: #2094fc;
    border-color: #2094fc;
    color: white;
}
